/**
* @Author: li
* @Date: 2019
* @Description: route 配置
*/
import React from 'react'
import {HashRouter as Router, Switch} from 'react-router-dom'
import PageRouter from './PageRouter'

class AppRouter extends React.Component {
  render() {
    return (
      <div className='body_type' id="root_body">
        <Router>
          <Switch>
            <PageRouter></PageRouter>
          </Switch>
        </Router>
      </div>
    )
  }
}
export default AppRouter