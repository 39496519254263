/**
 * @Author: li
 * @Date: 2019
 * @Description: pages route ??
 */
// pagerouter表示页面的路由
import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import asyncComponent from './AsyncComponent'
import App from '../pages/app';
import { withTranslation } from "react-i18next"
// import { Layout } from '../components/Auth/Layout';
import { Home } from '../components/Auth/Home';
import { FetchData } from '../components/Auth/FetchData';
import { Counter } from '../components/Auth/Counter';
import AuthorizeRoute from '../components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from '../components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';


// import '../components/Auth/custom.css'

const AskMe = asyncComponent(() => import('../pages/chat/askMe'))
const PageFrame = asyncComponent(() => import('../pages/iframe/pageFrame'))
const ChatSetting = asyncComponent(() => import('../pages/setting/chatSetting'))


class PageRouter extends React.Component {
  render() {
    return (
      <Switch>
        <App {...this.props}>
          <Route exact path='/kaka' component={Home} />

          <Route path='/counter' component={Counter} />
          <AuthorizeRoute path='/fetch-data' component={FetchData} />
          <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />


          <Route path="/chat-official" render={(props) => <AskMe official={true} {...this.props} />} />
          <Route path="/profile" render={(props) => <PageFrame {...this.props} href="https://www.novagpt.ai/account/my-account" />} />
          <Route path="/pricing" render={(props) => <PageFrame {...this.props} href="https://www.novagpt.ai/pricing-plans" />} />
          <Route path="/billing" render={(props) => <PageFrame {...this.props} href="https://www.novagpt.ai/account/my-subscriptions" />} />
          <Route path="/chat-settings" render={(props) => <ChatSetting {...this.props} />} />
        </App>
      </Switch>
    )
  }
}
const mapStateToProps = state => {
  return state
}
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(actions, dispatch)
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(withTranslation()(PageRouter)))