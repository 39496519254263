import React from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined, DownOutlined, SettingOutlined } from '@ant-design/icons';
import { Layout, Avatar, Popover, Button, Dropdown } from 'antd';
import '../style/header.less';
import i18n from 'i18next';
import { LoginMenu } from '../components/api-authorization/LoginMenu';

const { Header } = Layout;

class HeaderBar extends React.Component {
  state = {
    language: 'en',
    showLanguage: "En",
    promptsLibOpen: false,
  }
  componentDidMount() {
  }

  componentDidUpdate() {
    if (this.props.reducer.chatInfo.promptsLibOpen !== this.state.promptsLibOpen) {
      this.setState({ promptsLibOpen: this.props.reducer.chatInfo.promptsLibOpen })
    }
  }
  
  changePath(url) {
    this.props.history.push(url);
  }

  changeLanguage(lan) {
    if (lan === 'en') {
      i18n.changeLanguage('zh');
      this.setState({language: 'zh', showLanguage: "中文"})
    } else if (lan === 'zh') {
      i18n.changeLanguage('en');
      this.setState({language: 'en', showLanguage: "En"})
    }
  }

  openLibrary() {
    // console.log(this.state.promptsLibOpen, this.state.promptsLibOpen ? false : true)
    // console.log(this.props.reducer.chatInfo)

    if (this.props.history.location.pathname !== '/chat-official') {
      // 路由不在聊天页面时，跳转到聊天页面，并打开library
      this.props.history.push('/chat-official');
      this.props.setChatInfo({
        promptsLibOpen: true,
      });
    } else {
      this.props.setChatInfo({
        promptsLibOpen: this.state.promptsLibOpen ? false : true,
      });
    }
  }

  render() {
    const {collapsed, smallScreen, moveToOpen, user, t} = this.props;
    const content = (
      <div className='header_dropdown'>
        <div className='hd_header'>
          <div className='dropdown_btn'>
            <Avatar className='person_box'>{user.shortName}</Avatar>
            <div className='person_name'>{user.name}</div>
          </div>
        </div>
        {/* <div className='hd_line'></div>
        <div className='hd_body'>
          <div className='dropdown_btn' onClick={()=>{ this.changePath('/profile') }}><UserOutlined />{t('header.My Profile')}</div>
          <div className='dropdown_btn' onClick={()=>{ this.changePath('/pricing') }}><MoneyCollectOutlined />{t('header.Pricing')}</div>
          <div className='dropdown_btn' onClick={()=>{ this.changePath('/billing') }}><CreditCardOutlined />{t('header.Billing')}</div>
        </div> */}
        <div className='hd_line'></div>
        <div className='hd_bottom'>
          <div className='dropdown_btn' onClick={() => {
            // 退出登录
            this.props.userLogOut()
          }}><LogoutOutlined />{t('header.Log Out')}</div>
        </div>
      </div>
    );
    const items = [
      {
        label: <div onClick={()=>{ this.changeLanguage('zh') }}>English</div>,
        key: 'En',
      },
      {
        label: <div onClick={()=>{ this.changeLanguage('en') }}>中文</div>,
        key: 'Zh',
      },
    ];
    return (
      <div className="header_bar">
        <Header>
          <div className='header_lt'>
            {smallScreen ? React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'nav_close',
              onClick: () => {
                if (!collapsed && moveToOpen) {
                  this.props.setNavData({collapsed: false, moveToOpen: false})
                  return
                }
                this.props.setNavData({collapsed: !collapsed, moveToOpen: !collapsed})
              },
            }): null}
                    <Button className='header_prompt_btn' size='middle' onClick={() => { this.openLibrary() }} >{t('header.Prompts library')}</Button>

                   
                </div>

          <div className='header_gt'>
            <Dropdown
              menu={{
                items,
              }}
            >
              <div className='header_language'>
                {this.state.showLanguage}
                <DownOutlined />
              </div>
            </Dropdown>
            {user?.role === "System" &&
              <SettingOutlined className='header_language' onClick={()=>{ this.changePath("/chat-settings") }}/>}
            <Popover content={content} trigger="click" arrow={false} placement="bottomRight">
              <Avatar className='person_box' size={34}>{user.shortName}</Avatar>
            </Popover>
          </div>
        </Header>
      </div>
    )
  }
}

export default HeaderBar