import React from 'react';
import { Form, Input, Button, message } from 'antd';

class DynamicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formCode: '', // 表单code
      formValues: {}, // 表单数值,
      btnValue: {}, // 选中按钮,
      success: false, // 是否提交成功
      isUploading: false, // 是否正在提交
    };
  }
  componentDidMount() {
    // console.log(this.props.fData)
    this.setState({
      formCode: this.props.fData?.formCode || '',
    },()=>{ this.props.init?.() })
  }

  handleFormSubmit = (values) => {
    // console.log('Form submitted:', values);
    // 在这里可以处理表单提交的逻辑
    setTimeout(() => {
      this.formAction(values);
    });
  };

  formAction = (values) => {
    // console.log(this)
    if (this.state.success || this.state.isUploading) return;
    this.setState({isUploading: true});
    let btn = this.state.btnValue;
    let obj = {
      "jsonData": JSON.stringify(values),
      "formCode": this.state.formCode,
    };
    const requestOptions = {
      method: btn.action_method,
      body: JSON.stringify(obj)
    };

    fetch(`${btn.action}`, requestOptions).then((res)=>{
      // if (res && res.success) {
        this.setState({success: true, isUploading: false});
        message.success(res.message || "Form submitted successfully!");
      // }
    }).catch((err)=>{
      this.setState({isUploading: false});
      message.error("Form submitted failed!");
    })
  }

  renderFormFields = () => {
    const { fields } = this.props.fData;
    // const { formValues } = this.state;

    return fields.map((field) => {
      // options
      const { type, label, name, defaultValue, required } = field;

      if (type === 'Text') {
        return (
          <Form.Item
            key={name}
            label={name}
            name={label}
            initialValue={defaultValue}
            rules={[
              { required: required, message: `${name} is required` },
            ]}
          >
            <Input />
          </Form.Item>
        );
      }

      // 在这里可以根据不同的字段类型添加其他表单项，如下拉选择、日期选择等

      return null;
    });
  };

  // 点击选中按钮
  clickBtn = (button) => {
    this.setState({btnValue: button})
  }

  render() {
    const { title, description, buttons } = this.props.fData;

    return (
      <div className='char_form'>
        <h1>{title}</h1>
        <p>{description}</p>
        <Form onFinish={this.handleFormSubmit}>
          {this.renderFormFields()}
          <Form.Item>
            {buttons.map((button) => {
              // label, 
              const { type, name } = button;
              if (type === 'button') {
                return (
                  <Button key={name} type="primary" htmlType="submit" onClick={()=>{ this.clickBtn(button) }} disabled={this.state.success}>
                    {name}
                  </Button>
                );
              }
              // 在这里可以根据不同的按钮类型添加其他按钮，如取消按钮等
              return null;
            })}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default DynamicForm;
