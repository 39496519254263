import React from 'react';
import fetch from '../../fetch/axios';
import '../../style/askMe.less';
import { Tabs, Select, Button, Input, Pagination, Modal, Tag, message, Popconfirm, Skeleton } from 'antd';
import { SearchOutlined, StarOutlined, StarFilled, EditOutlined, CheckCircleOutlined, PlusOutlined, EyeOutlined, DeleteOutlined, LikeFilled, LikeOutlined } from '@ant-design/icons';
import '../../style/promtsLib.less';
// import moment from 'moment';

const { TextArea } = Input;

class PromptsLib extends React.Component {
  state = {
    tabIndex: "1",
    topic: "All",
    activity: "All",
    sortBy: "All",
    search: "",
    categoryList: [],
    listLoading: false,
    promptsList: [],

    topicList: [
      {
        value: 'All',
        label: 'All',
      }
    ],
    activityList: [
      {
        value: 'All',
        label: 'All',
      }
    ],

    page: 1,
    pageSize: 8,
    pageSum: 8,

    selectId: '',

    isModalOpen: false,
    newPrompt: {
      "ID": 0,
      "Topic": "All",
      "Activity": "All",
      "Title": "",
      "Teaser": "",
      "PromptHint": "",
      "PromptTemplate": "",
    },
    addActivityList: [
      {
        value: 'All',
        label: 'All',
      }
    ],
    isAddCheckError: false,
    isPreview: false,
  }
  inputRef = React.createRef();
  componentDidMount() {
    this.getCategoryList();
    this.getPromptsList();
  }

  componentDidUpdate() {

  }

  tabChange(e) {
    this.setState({tabIndex: e, page: 1}, () => {
      this.getPromptsList();
    })
  }
  changeTopic(e) {
    this.setState({topic: e})
    if (e === 'All') {
      this.setState({activityList: [{value: 'All', label: 'All'}], activity: 'All', page: 1})
    }
    if (e !== 'All') {
      // 从categoryList中找到value等于e的项,取出该项的ID
      let topicId = this.state.categoryList.filter((item) => {
        return item.value === e;
      })?.[0]?.id;
      if (!topicId) return;
      // 从categoryList中找到parent_ID等于topicId的项
      let activityList = this.state.categoryList.filter((item) => {
        return item.parent_ID === topicId;
      })
      this.setState({activityList: [{value: 'All', label: 'All'}, ...activityList], activity: 'All', page: 1})
    }
    // 查询数据
    setTimeout(() => {
      this.getPromptsList();
    });
  }
  changeActivity(e) {
    this.setState({activity: e, page: 1}, () => {
      this.getPromptsList();
    })
  }
  changeOrderBy(e) {
    this.setState({sortBy: e, page: 1}, () => {
      this.getPromptsList();
    })
  }
  changeSearch(e) {
    this.setState({search: e.target.value})
  }
  enterToSearch(e) {
    if (e.keyCode === 13) {
      this.setState({page: 1}, () => {
        this.state.search.trim() && this.getPromptsList();
      })
    }
  }

  // 获取分类数据 设置父目录
  getCategoryList() {
    fetch.get(`${process.env.URL}/api/Prompt/Category`).then((res) => {
      if (res && res.success) {
        let categoryList = res.data;
        // 给每一项添加一个value属性，该属性等于label属性
        categoryList.forEach((item) => {
          item.value = item.label;
        })
        // 取出所有 parent_ID: 0 的数据
        let parentList = categoryList.filter((item) => {
          return item.parent_ID === 0;
        })
        let topicList = [{value: 'All', label: 'All'}, ...parentList];
        this.setState({categoryList, topicList: topicList})
      }
    })
  }

  changePage(page, size) {
    this.setState({page, pageSize: size}, () => {
      this.getPromptsList();
    })
  }

  // 选中Prompts
  selectPrompts(item) {
    // console.log(item);
    this.setState({selectId: item.id})
    this.props.selectPrompts?.(item);
  }

  getPromptsList() {
    this.setState({promptsList: [], listLoading: true})
    let str = this.generateQuery();

    fetch.get(`${process.env.URL}/api/Prompt/Libary${str}`).then((res) => {
      if (res && res.success) {
        let search = this.generateQuery();
        if (str !== search) return;
        this.setState({promptsList: res.data, pageSum: res.totalCount, listLoading: false})
      }
    })
  }

  // 生成query
  generateQuery() {
    let str = '';
    str= `?tab=${this.state.tabIndex}&page=${this.state.page}&size=${this.state.pageSize}`;
    if (this.state.topic !== 'All') {
      str += `&topic=${this.state.topic}`;
    }
    if (this.state.activity !== 'All') {
      str += `&activity=${this.state.activity}`;
    }
    if (this.state.sortBy !== 'All') {
      str += `&order=${this.state.sortBy}`;
    }
    if (this.state.search !== '') {
      str += `&title=${this.state.search}`;
    }
    return str;
  }

  // 打开添加弹窗
  openAddModal() {
    this.setState({isModalOpen: true})
  }
  // 关闭添加弹窗
  closeAddModal() {
    this.setState({
      isModalOpen: false,
      isPreview: false,
      newPrompt: {
        "ID": 0,
        "Topic": "All",
        "Activity": "All",
        "Title": "",
        "Teaser": "",
        "PromptHint": "",
        "PromptTemplate": "",
      },
    })
  }
  // 光标处插入
  handleClick(str) {
    if (!str) return;
    const input = this.inputRef.current?.resizableTextArea?.textArea; // 获取 input DOM 节点
    if (input) {
      const { selectionStart, selectionEnd } = input;
      // console.log(selectionStart, selectionEnd);
      let newPrompt = this.state.newPrompt;
      const newValue =
      newPrompt.PromptTemplate.substring(0, selectionStart) + str + newPrompt.PromptTemplate.substring(selectionEnd);
      newPrompt.PromptTemplate = newValue;
      this.setState({ newPrompt });
      input.focus(); // 保持焦点在输入框中
      // 更新光标位置
      input.selectionStart = selectionStart + str.length;
      input.selectionEnd = selectionStart + str.length;
    }
  };
  // 处理输入
  changePrompt(e, type) {
    let newPrompt = this.state.newPrompt;
    if (type === "Topic") {
      this.changeAddTopic(e);
      newPrompt.Activity = 'All';
    }
    if (type === "Activity" || type === "Topic") {
      newPrompt[type] = e;
    } else {
      newPrompt[type] = e.target.value;
    }
    this.setState({newPrompt})
  }
  // 添加时，选择Topic时，改变Activity
  changeAddTopic(e) {
    if (e === 'All') {
      this.setState({addActivityList: [{value: 'All', label: 'All'}]})
    }
    if (e !== 'All') {
      // 从categoryList中找到value等于e的项,取出该项的ID
      let topicId = this.state.categoryList.filter((item) => {
        return item.value === e;
      })?.[0]?.id;
      if (!topicId) return;
      // 从categoryList中找到parent_ID等于topicId的项
      let activityList = this.state.categoryList.filter((item) => {
        return item.parent_ID === topicId;
      })
      this.setState({addActivityList: [{value: 'All', label: 'All'}, ...activityList]})
    }
  }
  // 创建、修改Prompt
  createPrompt() {
    let newPrompt = JSON.parse(JSON.stringify(this.state.newPrompt));
    if (!newPrompt.PromptTemplate || !newPrompt.Teaser || !newPrompt.PromptHint || !newPrompt.Title ||  newPrompt.Topic === 'All' || (newPrompt.Activity === 'All' && this.state.addActivityList.length > 1)) {
      this.setState({isAddCheckError: true})
      return message.warning('Please check the form and make sure everything is filled out');
    }
    if (newPrompt.Activity === 'All') {
      delete newPrompt.Activity;
    }
    this.setState({isAddCheckError: false, isOperating: true})
    fetch.post(`${process.env.URL}/api/prompt/save`, newPrompt).then((res) => {
      if (res && res.success) {
        this.setState({isOperating: false})
        this.getPromptsList();
        this.closeAddModal();
      }
    })
  }
  // 预览Prompt
  previewPrompt(event, id, isEdit) {
    event.stopPropagation();
    // console.log(id);
    fetch.get(`${process.env.URL}/api/prompt/${id}`).then((res) => {
      // console.log(res);
      if (res) {
        let newPrompt = this.state.newPrompt;
        newPrompt.ID = res.id;
        newPrompt.Topic = res.topic;
        newPrompt.Activity = res.activity;
        newPrompt.Title = res.title;
        newPrompt.Teaser = res.teaser;
        newPrompt.PromptHint = res.promptHint;
        newPrompt.PromptTemplate = res.promptTemplate;
        this.setState({
          newPrompt,
          isModalOpen: true,
          isPreview: !isEdit,
        })
      }
    })
  }
  // 删除Prompt
  deletePrompt(event, id) {
    event.stopPropagation();
    fetch.post(`${process.env.URL}/api/prompt/Delete/${id}`).then((res) => {
      if (res && res.success) {
        this.getPromptsList();
      }
    })
  }
  // 收藏Prompt
  collectPrompt(event, id) {
    event.stopPropagation();
    fetch.get(`${process.env.URL}/api/prompt/ToggleFavorite/${id}`).then((res) => {
      if (res && res.success) {
        if (this.state.tabIndex === '4') {
          this.getPromptsList();
        } else {
          let promptsList = JSON.parse(JSON.stringify(this.state.promptsList));
          // 找到id对应的项
          let item = promptsList.filter((item) =>  item.id === id)[0];
          if (item) {
            item.user_collect = !item.user_collect;
            this.setState({promptsList})
          }
        }
      }
    })
  }
  // 点赞Prompt
  voteLike(event, id, type) {
    event.stopPropagation();
    fetch.get(`${process.env.URL}/api/Prompt/vote/${id}/${type}`).then((res) => {
      if (res && res.success) {
        let promptsList = JSON.parse(JSON.stringify(this.state.promptsList));
        // 找到id对应的项
        let item = promptsList.filter((item) =>  item.id === id)[0];
        if (item) {
          if (type === 0) {
            item.is_dislike = true;
            item.dislikeCount++;
          } else if (type === 1) {
            item.is_like = true;
            item.likesCount++;
          }
          this.setState({promptsList})
        }
      }
    })
  }
  // 取消点赞Prompt
  voteCancel(event, id, type) {
    event.stopPropagation();
    fetch.get(`${process.env.URL}/api/Prompt/votecancel/${id}/${type}`).then((res) => {
      if (res && res.success) {
        let promptsList = JSON.parse(JSON.stringify(this.state.promptsList));
        // 找到id对应的项
        let item = promptsList.filter((item) =>  item.id === id)[0];
        if (item) {
          if (type === 0) {
            item.is_dislike = false;
            item.dislikeCount--;
          } else if (type === 1) {
            item.is_like = false;
            item.likesCount--;
          }
          this.setState({promptsList})
        }
      }
    })
  }


  render() {
    const { t } = this.props;
    const items = [
      {
        key: '1',
        label: t(`pLib.Editor's choice`),
      },
      {
        key: '2',
        label: t(`pLib.Public prompts`),
      },
      {
        key: '3',
        label: t(`pLib.Personal prompts`),
      },
      {
        key: '4',
        label: t(`pLib.Favorite`),
      },
    ];
    const showTotal = (total) => t(`pLib.Total`, {total});
    return (
      <div className='pl_box'>
        <div className='pl_header'>
          <div className='pl_tab_line'>
            <Tabs className='pl_tab' defaultActiveKey="1" items={items} onChange={(e)=>{ this.tabChange(e) }}/>
          </div>
          <div className='pl_search'>
            <div className='pl_s_item'>
              <div className='pl_s_title'>{t('pLib.Topic')}</div>
              <Select
                value={this.state.topic}
                onChange={(e)=>{ this.changeTopic(e) }}
                options={this.state.topicList}
              />
            </div>
            <div className='pl_s_item'>
              <div className='pl_s_title'>{t('pLib.Activity')}</div>
              <Select
                value={this.state.activity}
                onChange={(e)=>{ this.changeActivity(e) }}
                options={this.state.activityList}
              />
            </div>
            <div className='pl_s_item'>
              <div className='pl_s_title'>{t('pLib.Sort By')}</div>
              <Select
                value={this.state.sortBy}
                onChange={(e)=>{ this.changeOrderBy(e) }}
                options={[
                  {
                    value: 'All',
                    label: t('pLib.Default'),
                  },
                  {
                    value: '3',
                    label: t('pLib.Top Votes'),
                  },
                  {
                    value: '1',
                    label: t('pLib.Top Views'),
                  },
                  {
                    value: '2',
                    label: t('pLib.Latest Updates'),
                  }
                ]}
              />
            </div>
            <div className='pl_s_item'>
              <div className='pl_s_title'>&nbsp;</div>
              <Input
                value={this.state.search}
                // size='large'
                className='search'
                placeholder={t('pLib.Search')}
                prefix={<SearchOutlined />}
                onChange={(e)=>{ this.changeSearch(e) }}
                onKeyDown={(e)=>{ this.enterToSearch(e) }}
              />
            </div>
          </div>
        </div>
        <div className='pl_main'>
          {this.state.tabIndex === '3' && (
            <div className='pl_add'>
              <Button size='large' icon={<PlusOutlined />} onClick={()=>{ this.openAddModal() }}>{t('pLib.Add new prompt template')}</Button>
            </div>
          )}
          <div className='pl_list'>
            {this.state.promptsList.map((item, index)=>{
              return (
                <div className={this.state.selectId === item.id ? 'pl_list_item pit_choosed':'pl_list_item'} key={index} onClick={()=>{ this.selectPrompts(item) }}>
                  <div className='pl_list_item_inner'>
                    <div className='pl_list_item_title' title={item.title}>{item.title}</div>
                    <div className='pl_list_item_tag'>{item.topic} / {item.activity}</div>
                    <div className='pl_list_item_teaser'>{item.teaser}</div>
                    <div className='pl_list_bottom'>
                      <div className='pl_list_bottom_opt'>
                        <Button size='small' type="text" icon={<CheckCircleOutlined />}> {item.useSummary || 0}</Button>
                        {item.is_like? 
                          <Button size='small' type="text" icon={<LikeFilled />} onClick={(e)=>{ this.voteCancel(e, item.id, 1) }}> {item.likesCount}</Button>:
                          <Button size='small' type="text" icon={<LikeOutlined />} onClick={(e)=>{ this.voteLike(e, item.id, 1) }}> {item.likesCount}</Button>
                        }
                        {/* {item.is_dislike? 
                          <Button size='small' type="text" icon={<DislikeFilled />} onClick={(e)=>{ this.voteCancel(e, item.id, 0) }}> {item.dislikeCount}</Button>:
                          <Button size='small' type="text" icon={<DislikeOutlined />} onClick={(e)=>{ this.voteLike(e, item.id, 0) }}> {item.dislikeCount}</Button>
                        } */}
                      </div>
                      <div className='pl_list_bottom_opt'>
                        {item.user_collect?
                          <Button size='small' type="text" icon={<StarFilled />} onClick={(e)=>{ this.collectPrompt(e, item.id) }}></Button>:
                          <Button size='small' type="text" icon={<StarOutlined />} onClick={(e)=>{ this.collectPrompt(e, item.id) }}></Button>
                        }
                    
                        {this.state.tabIndex === '3' ?
                          <>
                            <Button size='small' type="text" icon={<EditOutlined />} onClick={(e)=>{ this.previewPrompt(e, item.id, true) }}></Button>
                            <Popconfirm
                              title={t("pLib.Delete the Prompt")}
                              description={t("pLib.Are you sure to delete this prompt?")}
                              okText={t("pLib.Yes")}
                              cancelText={t("pLib.No")}
                              onConfirm={(e)=>{ this.deletePrompt(e, item.id) }}
                            >
                              <Button size='small' type="text" icon={<DeleteOutlined />}></Button>
                            </Popconfirm>
                          </>:
                          <Button size='small' type="text" icon={<EyeOutlined />} onClick={(e)=>{ this.previewPrompt(e, item.id, false) }}></Button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {this.state.listLoading && (
            <div className='pl_list'>
              <Skeleton
                title={true}
                active
              />
              <Skeleton
                title={true}
                active
              />
              <Skeleton
                title={true}
                active
              />
              <Skeleton
                title={true}
                active
              />
            </div>
          )}
          <div className='pl_pagination'>
            <Pagination
              size="small"
              total={this.state.pageSum}
              showTotal={showTotal}
              showSizeChanger={true}
              // showQuickJumper
              pageSize={this.state.pageSize}
              pageSizeOptions={['8', '16', '32', '64']}
              onChange={(page, size)=>{ this.changePage(page, size) }}
            />
          </div>
        </div>
        <Modal open={this.state.isModalOpen} onOk={()=>{ this.createPrompt() }} onCancel={()=>{ this.closeAddModal() }} className={this.state.isPreview&&'model_preview'}>
          <div className='pl_modal_box'>
            <div className='pl_modal_title'>{t('pLib.Prompt Title')}</div>
            <Input
              value={this.state.newPrompt.Title}
              onChange={(e)=>{ this.changePrompt(e, "Title") }}
              placeholder={t("pLib.Please enter the title")}
              status={this.state.isAddCheckError&&!this.state.newPrompt.Title&&'error'}
              disabled={this.state.isPreview}
            />
            <div className='pl_modal_title'>{t('pLib.Prompt Description')}</div>
            <TextArea
              value={this.state.newPrompt.Teaser}
              onChange={(e)=>{ this.changePrompt(e, "Teaser") }}
              placeholder={t("pLib.Please enter the teaser")}
              autoSize={{
                minRows: 2,
                maxRows: 2,
              }}
              status={this.state.isAddCheckError&&!this.state.newPrompt.Teaser&&'error'}
              disabled={this.state.isPreview}
            />
            <div className='pl_modal_title'>{t('pLib.Prompt Template')}</div>
            <TextArea
              ref={this.inputRef}
              value={this.state.newPrompt.PromptTemplate}
              onChange={(e)=>{ this.changePrompt(e, "PromptTemplate") }}
              placeholder={t("pLib.Please enter the prompt template")}
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}
              status={this.state.isAddCheckError&&!this.state.newPrompt.PromptTemplate&&'error'}
              disabled={this.state.isPreview}
            />
            {!this.state.isPreview && (
              <div className='pl_modal_tag_bar'>
                <Tag onClick={()=>{ this.handleClick("[PROMPT]") }}>[PROMPT]</Tag>
                <Tag onClick={()=>{ this.handleClick("[TARGETLANGUAGE]") }}>[TARGETLANGUAGE]</Tag>
              </div>
            )}
            <div className='pl_modal_title'>{t('pLib.Prompt Hint')}</div>
            <Input
              value={this.state.newPrompt.PromptHint}
              onChange={(e)=>{ this.changePrompt(e, "PromptHint") }}
              placeholder={t("pLib.Please enter the prompt hint")}
              status={this.state.isAddCheckError&&!this.state.newPrompt.PromptHint&&'error'}
              disabled={this.state.isPreview}
            />
            
            <div className='pl_modal_type'>
              <div>
                <div className='pl_modal_title'>{t('pLib.Topic')}</div>
                <Select
                  value={this.state.newPrompt.Topic}
                  onChange={(e)=>{ this.changePrompt(e, "Topic") }}
                  options={this.state.topicList}
                  status={this.state.isAddCheckError&&this.state.newPrompt.Topic === 'All' &&'error'}
                  disabled={this.state.isPreview}
                />
              </div>
              <div>
                <div className='pl_modal_title'>{t('pLib.Activity')}</div>
                <Select
                  value={this.state.newPrompt.Activity}
                  onChange={(e)=>{ this.changePrompt(e, "Activity") }}
                  options={this.state.addActivityList}
                  status={this.state.isAddCheckError&&this.state.newPrompt.Activity === 'All'&&this.state.addActivityList.length > 1&&'error'}
                  disabled={this.state.isPreview}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default PromptsLib