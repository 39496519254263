import React from 'react'
import { InfoCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { Avatar, Button, message } from 'antd';
import BotAvt from '../../images/chatbot_avatar.png';
import ChatForm from './chatFrom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
// import FilePreview from "../../components/filePreview";
import RcViewer from '@hanyk/rc-viewer';
import LazyImage from '../../components/lazyImage';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {coldarkDark} from 'react-syntax-highlighter/dist/esm/styles/prism'

class QAModule extends React.Component {
  state = {
    data: {},
    index: undefined,
    imgLoadedArr: [], // 图片懒加载完成
  }
  componentDidMount() {
    const { data, index } = this.props;
    this.setState({data, index})
  }

  msgClicked(e, row) {
    // console.log(e, row)
    let ele = e.target;
    if (ele && ele.tagName === "SUP" && ele.id && row?.source_files) {
      let index = parseInt(ele.id) - 1;
      let fileArr = row.source_files.sort((a,b)=>a.index - b.index)[index];
      if (fileArr) {
        this.props.viewFile?.(fileArr);
      }
    }
  }

  // 获取文件信息
  getFileInfo(obj) {
    if (obj.getFile) {
      obj.getFile = false;
    } else {
      obj.getFile = true;
    }
    this.setState({data:obj})
  }

  // 图片懒加载完成数量
  onLoaded(src) {
    let imgLoadedArr = this.state.imgLoadedArr;
    if (imgLoadedArr.includes(src)) return;
    imgLoadedArr.push(src);
    this.setState({imgLoadedArr})
  }

  render() {
    const { data } = this.state;

    const { user } = this.props.reducer;
    const { projectType, t } = this.props;
    const options = {
      // 是否显示下面工具栏 1 显示 0 隐藏
      // toolbar: 1,
      navbar: false,       //关闭缩略图
      fullscreen: false,   //播放全屏
      loop: false,         //是否循环 上一个 下一个
      minWidth:'',
      minHeight:'',
      toolbar: {
        zoomIn: { size: 'large' },  //放大
        zoomOut: { size: 'large' }, //缩小
        reset: { size: 'large' },   //重置
        prev: { show: true, size: 'large', }, //上一张
        play: { show: false, size: 'large', },  //播放
        next: { show: true, size: 'large', },  //下一张
        rotateLeft: { size: 'large' },  //左旋转
        rotateRight: { size: 'large' }, //右旋转
        flipHorizontal: { size: 'large' },  //左右翻转
        flipVertical: { size: 'large' },    //上下翻转
      },
    };
    return (
      <div className='chat_item'>
        {/* 提问 */}
        {data.question?.trim() !== "" &&
          <div className='chat_me_bg'>
            <div className='chat_me'>
              <Avatar className='chat_me_icon'>{user.shortName}</Avatar>
              {/* <div className='chat_me_icon'>C</div> */}
              <div className='chat_me_msg'>{data.question}</div>
            </div>
          </div>
        }
        {/* 回答 */}
        <div className='chat_bot_bg'>
          <div className='chat_bot'>
            <div className='chat_bot_icon'>
              <img src={BotAvt} alt=''></img>
            </div>
            <div className='chat_box_bubble'>
              <div className='chat_bot_msg'
                onClick={(e, a) => {
                  this.msgClicked(e, data)
                }}
              >
                {data.chatTemplater_ID > 0 &&
                  <div className="chat_bot_helper">
                    <div className='chat_emoji'>{data.chatTemplater_Img}</div>
                    {data.chatTemplater_Title}
                  </div>
                }
                <RcViewer
                  options={options}
                // ref={(v) => {
                //   console.log(v);
                //   // setPreview(v);
                // }}
                >
                  {data.answer?.trim() !== "" &&
                    <ReactMarkdown
                      className='markdown'
                      rehypePlugins={[rehypeRaw]}
                      remarkPlugins={[remarkGfm]}
                      components={{
                        'em': ({ node, ...props }) => {
                          console.log(node, props)
                          if (props.children[0] && typeof props.children[0] === 'string' && props.children[0].startsWith('^sup')) {
                            return <sup>{props.children[0].substring(4)}</sup>
                          }
                          if (props.children[0] && typeof props.children[0] === 'string' && props.children[0].startsWith('~sub')) {
                            return <sub>{props.children[0].substring(4)}</sub>
                          }
                          return <em {...props} />
                        },
                        'img': ({ node, ...props }) => {
                          // console.log(node, props, this.state.imgLoadedArr)
                          console.log("-")
                          if ((this.state.imgLoadedArr.includes(props.src) || props.src.startsWith('data:image') || props.src.startsWith('blob:'))) {
                            return <img src={props.src} alt={props.alt || ""}/>
                          }
                          return <LazyImage {...props} onLoaded={(src)=>{ this.onLoaded(src) }}/>
                          // return <LazyImage {...props} />
                        },
                        pre({ children }) {
                          const match = /language-(\w+)/.exec(children[0]?.props?.className || '')
                          return <div className='chat_code_box'>
                            <div className='chat_code_header'>
                              <div>{match?.[1]}</div>
                              <Button
                                onClick={()=>{ 
                                  navigator.clipboard.writeText(children[0].props.children[0]);
                                  message.success('Copied to clipboard.')
                                }}
                                icon={<CopyOutlined />}
                                size='small'
                                type='text'
                              >Copy code</Button>
                            </div>
                            {children}
                          </div>
                        },
                        code({node, inline, className, children, ...props}) {
                          const match = /language-(\w+)/.exec(className || '')
                          // console.log(node, inline, className, children, props)
                          // console.log(match)
                          return !inline ? (
                            <SyntaxHighlighter
                              {...props}
                              children={String(children).replace(/\n$/, '')}
                              style={coldarkDark}
                              language={match?.[1] || undefined}
                              PreTag="div"
                            />
                          ) : (
                            <code {...props} className={className}>
                              {children}
                            </code>
                          )
                        }
                      }}
                    >{data.answer}</ReactMarkdown>
                  }
                </RcViewer>
                {this.props.responeseType === 'text' && data.suggestQuestions?.length > 0 ?
                  <div className='chat_bot_questions'>
                    <div className='chat_bot_ques_title'>{t('chat.Follow-ques')}</div>
                    {data.suggestQuestions?.map((it, id) => {
                      return (
                        <div className='chat_bot_ques_item' key={id} onClick={() => {
                          console.log(it.text_Type)
                          if (it.text_Type === "Forms" && it.forms) {
                            this.props.getForm?.(it.forms)
                          } else {
                            this.props.search?.(it.question, it.number)
                          }
                        }} title={it.question}>
                          <div>{it.question}</div>
                        </div>
                      )
                    })}
                  </div>
                  : null}
                {/* 表单 */}
                {data.searchType === "form" &&
                  <ChatForm fData={data.formData} init={() => { this.props.scrollToBottom?.() }}></ChatForm>
                }
              </div>
              {projectType === "File" && data.source_files?.length > 0 ?
                <div className='chat_bot_info'>
                  <div className='chat_bot_info_header'>
                    Where did this answer come from?
                    <InfoCircleOutlined className="get_file" onClick={() => { this.getFileInfo(data) }} />
                  </div>
                  {data.getFile ?
                    <div className='chat_bot_info_body'>
                      {/* <div className='chat_bot_info_title'>Sources {`${data.source_files.length}/${data.source_files.length}`}</div>
                              {data.source_files.length === 0 ? 
                                <LoadingOutlined />
                              :null} */}
                      {data.source_files.sort((a, b) => a.index - b.index).map((it, id) => {
                        return (
                          <div className='chat_bot_info_item' key={id}
                            onClick={() => { this.props.viewFile?.(it) }}
                          >{it.index}. {it.filename}</div>
                        )
                      })}
                    </div>
                    : null}
                </div>
                : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default React.memo(QAModule);